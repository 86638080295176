import React, { useEffect, useRef, useState } from 'react'
import { Answer, Answers, HumanSizesType } from '@/types/types'

import { getValue } from '@/helpers/getObjectValue'
import { BMITooltip } from './BMITooltip'
import { useTranslation } from 'react-i18next'
import { GetMoving } from './GetMoving'
import { Link } from 'react-router-dom'
import { SHOW_AGREE_TERMS } from '@/constants/variables'

import height from '@/assets/images/body-height.svg'
import danger from '@/assets/images/dange.svg'

interface HumanSizesProps {
  onAnswer: (answer: Answer) => void
  attributes: HumanSizesType
  answers: Answers
  active: boolean
  isAgreeRules: boolean
  setIsAgreeRules: (status: boolean) => void
  isShakeAlert: boolean
}

export default function HumanSizes(props: HumanSizesProps) {
  const { t } = useTranslation()
  const [activeUnit, setActiveUnit] = useState(props.attributes.active ?? 'null')
  const [ftVal, setFtVal] = useState('')
  const [inVal, setInVal] = useState('')
  const [inputVal, setInputVal] = useState('')
  const [size, setSize] = useState('')
  const [bmi, setBMI] = useState(0)
  const [isSmallBmi, setIsSmallBmi] = useState(false)
  const [goalWeight, setGoalWeight] = useState(0)
  const ref = useRef(null)

  const changeUnits = (unit: string) => {
    if (activeUnit === unit) return

    setActiveUnit(String(unit))
    if (unit === 'FT' && inputVal !== '') {
      setFtVal(((+size / 30.48).toFixed(1) + '').split('.')[0])
      setInVal(((+size / 30.48).toFixed(1) + '').split('.')[1])
      setSize((+size / 30.48).toFixed(1) + '')
    }
    if (unit === 'CM' && ftVal !== '') {
      setSize(Math.round(+size * 30.48) + '')
      setInputVal(Math.round(+size * 30.48) + '')
    }

    if (unit === 'lbs' && inputVal) {
      const value = Number(inputVal)
      setInputVal(Math.round(value / 0.45359237).toString())
      changedWeight()
    }

    if (unit === 'kg' && inputVal) {
      const value = Number(inputVal)
      setInputVal(Math.round(value * 0.45359237).toString())
      changedWeight()
    }
  }

  const changeBMI = (weight: [number, string]) => {
    const heightProps = getValue('human-height', props.answers)
    const weightProps = weight
    if (heightProps && weightProps) {
      const tall =
        heightProps && heightProps[1] === 'FT' ? +heightProps[0] * 30.48 : +heightProps[0]
      const weight =
        weightProps && weightProps[1] === 'lbs' ? +weightProps[0] * 0.4536 : +weightProps[0]
      setBMI(weight / ((tall / 100) * (tall / 100)))
    }
  }

  const changedWeight = () => {
    const weightProps = getValue('current-weight', props.answers)
    if (weightProps) {
      const weight =
        weightProps && weightProps[1] === 'lbs' ? +weightProps[0] * 0.4536 : +weightProps[0]
      const goal = activeUnit === 'lbs' ? +inputVal * 0.4536 : +inputVal
      setGoalWeight(((weight - goal) / weight) * 100)
      changeBMI([+inputVal, activeUnit])
    }
  }

  const changeInVal = (e: any) => {
    if (ftVal === '8') {
      setInVal(String(0))
    } else {
      e.target.value.length > 2
        ? setInVal(String(e.target.value).slice(0, 2))
        : setInVal(String(e.target.value))
    }
  }

  const selectDefaultUnit = (attributes: HumanSizesType) => {
    const code = sessionStorage.getItem('countryCode') || 'US'
    const units: string[] = attributes?.units || ['']

    if (!Array.isArray(units) || !units.length) return

    if (units.length < 2) setActiveUnit(units[0])
    else setActiveUnit(code === 'US' ? units[0] : units[1])

    return null
  }

  useEffect(() => {
    const choosedValue = getValue(props.attributes.name, props.answers)
    if (choosedValue) {
      setActiveUnit(choosedValue[1])
      setSize(choosedValue[0])
      if (choosedValue[1] === 'FT') {
        setFtVal(((+choosedValue[0]).toFixed(1) + '').split('.')[0])
        setInVal(((+choosedValue[0]).toFixed(1) + '').split('.')[1])
      } else {
        setInputVal(choosedValue[0])
      }
    }

    selectDefaultUnit(props.attributes)
  }, [])

  useEffect(() => {
    if (props.active && props.attributes.name === 'goal-weight' && activeUnit === 'null') {
      const choosedValue = getValue('current-weight', props.answers)
      if (choosedValue) {
        setActiveUnit(choosedValue[1])
      }
    }
  }, [props.active])

  useEffect(() => {
    if (props.attributes.name === 'current-weight') {
      if (String(activeUnit) === 'lbs') {
        if (+inputVal >= 55 && +inputVal <= 305) {
          setSize(inputVal)
          changeBMI([+inputVal, activeUnit])
        } else {
          setSize('')
          setBMI(0)
        }
      }
      if (String(activeUnit) === 'kg') {
        if (+inputVal >= 25 && +inputVal <= 180) {
          setSize(inputVal)
          changeBMI([+inputVal, activeUnit])
        } else {
          setSize('')
          setBMI(0)
        }
      }
    }
    if (props.attributes.name === 'goal-weight' && inputVal) {
      changedWeight()
    }
    if (String(activeUnit) === 'CM') {
      if (+inputVal >= 80 && +inputVal <= 250) {
        setSize(inputVal)
      } else {
        setSize('')
      }
    }
  }, [inputVal, activeUnit])

  useEffect(() => {
    if (ftVal && +ftVal >= 3 && +ftVal < 8) {
      inVal && +inVal > 0 ? setSize(ftVal + '.' + inVal) : setSize(ftVal + '.' + 0)
    } else if (+ftVal === 8) {
      setSize(ftVal + '.' + 0)
      setInVal('0')
    } else {
      setSize('')
    }
  }, [ftVal, inVal])

  useEffect(() => {
    if (props.attributes.name === 'goal-weight' && bmi < 18.5) {
      setSize('')
      setIsSmallBmi(true)
    } else if (props.attributes.name === 'goal-weight') {
      setSize(inputVal)
      setIsSmallBmi(false)
    }
  }, [bmi, inputVal])

  useEffect(() => {
    if (props.active) {
      size && +size > 0
        ? props.onAnswer({
            [props.attributes.name]: [size, activeUnit],
          })
        : props.onAnswer({
            [props.attributes.name]: [],
          })
    }

    if (SHOW_AGREE_TERMS !== 'true') {
      props.setIsAgreeRules(true)
    }
  }, [activeUnit, size])

  return (
    <div className="flex flex-col items-center">
      <div className="inline-flex items-center justify-center before:rounded-r10 before:border before:border-borderInput before:absolute before:content-[''] before:top-0 before:left-0 before:w-full before:h-full relative">
        {props.attributes.units.map((unit) => {
          return (
            <div
              key={unit}
              className={`${activeUnit === unit ? 'bg-green text-white' : ''} relative transition-all  font-extrabold rounded-r10 py-1 px-4 w-16 flex items-center justify-center text-center uppercase`}
              onClick={() => changeUnits(unit)}
            >
              {unit}
            </div>
          )
        })}
      </div>
      {props?.attributes?.name === 'human-height' && (
        <>
          <div className="relative w-full">
            <div
              className={`${String(activeUnit) === 'FT' ? 'relative opacity-100 visible delay-300 top-0' : ' top-8 delay-0 absolute opacity-0 invisible'} transition-all min-w-full  duration-300 mt-8`}
            >
              <div className="flex items-center justify-between">
                <input
                  className="placeholder:text-center placeholder:-translate-y-0.5 placeholder:text-xl3 block w-full p-2 border border-borderInput bg-lightGray rounded-r12 text-xl6 font-extrabold outline-none text-dark placeholder:text-darkOpacity text-center"
                  type="number"
                  inputMode="numeric"
                  onChange={(e) => +e.target.value < 9 && setFtVal(String(e.target.value))}
                  placeholder="Height (ft)"
                  value={ftVal}
                />
                <span className="px-4 text-xl3 font-extrabold  text-darkOpacity">/</span>
                <input
                  className="placeholder:text-center placeholder:-translate-y-0.5 placeholder:text-xl3 block w-full p-2 border border-borderInput bg-lightGray rounded-r12 text-xl6 font-extrabold outline-none text-dark  placeholder:text-darkOpacity text-center"
                  type="number"
                  inputMode="numeric"
                  onChange={(e) => changeInVal(e)}
                  placeholder="Height (in)"
                  value={inVal}
                  ref={ref}
                />
              </div>
              <p className="text-md text-center mt-1 text-dark">
                {t('Please, enter a value from 3 ft to 8 ft')}
              </p>
            </div>
            <div
              className={`${String(activeUnit) === 'CM' ? 'relative opacity-100 visible delay-300 top-0' : ' top-8 delay-0 absolute opacity-0 invisible'} transition-all min-w-full duration-300 mt-8`}
            >
              <input
                className=" block w-full p-2 border border-borderInput bg-lightGray rounded-r12 text-xl6 font-extrabold outline-none text-dark placeholder:text-darkOpacity text-center"
                type="number"
                inputMode="numeric"
                onChange={(e) => setInputVal(String(e.target.value))}
                placeholder="Height"
                value={inputVal}
              />
              <p className="text-md text-center mt-1 text-dark">
                {t('Please, enter a value from 90 cm to 250 cm')}
              </p>
            </div>
          </div>

          {size && SHOW_AGREE_TERMS === 'true' ? (
            <>
              <div className="block w-full mt-5 p-2 text-xl6 font-extrabold outline-none text-dark placeholder:text-darkOpacity text-center">
                <label
                  className={`
                    text-md text-dark text-left pl-10 checkbox font-medium relative m-0 p-2 rounded-xl cursor-pointer transition duration-300  before:absolute before:content=[''] before:top-5 before:w-5 before:h-5  before:border before:bg-check before:left-2 before:rounded-md before:-translate-y-1/2 before:scale-95 
                    ${!props?.isAgreeRules ? 'before:border-[#222222]' : 'before:bg-green before:border-green before:bg-tick before:bg-center before:bg-60% before:bg-no-repeat '}
                  `}
                >
                  {`${t('I agree to allow to use my health-related onboarding data to deliver services and improve my user experience, as outlined in the')} `}
                  <Link
                    className="transition-all hover:text-primary text-[#3C60FD]"
                    to="/privacy-policy"
                  >
                    {t('Privacy Policy')}
                  </Link>
                  <input
                    type="checkbox"
                    checked={props?.isAgreeRules}
                    onChange={() => props?.setIsAgreeRules(!props?.isAgreeRules)}
                    className="hidden"
                  />
                </label>
              </div>

              <div
                className={`
                  block w-full p-4 pt-5 pb-5 mt-2 border border-dangerStroke bg-[#EF3B2E] rounded-r12 transition-all duration-300 
                  ${props?.isAgreeRules || props?.isShakeAlert ? 'opacity-0' : 'shake'}
                `}
              >
                <div className="flex items-center">
                  <img
                    className="mr-2 min-w-6"
                    src={danger}
                    width="32"
                    height="32"
                    alt="Height icon"
                  />
                  <p className="text-xl2 text-white font-normal leading-snug">
                    {t('Consent required to continue')}
                  </p>
                </div>
              </div>
            </>
          ) : (
            <div className="p-4 flex items-start  border border-borderInput bg-lightGray mt-8 rounded-r12">
              <img className="mr-4 mt-1" src={height} width="24" height="24" alt="Height icon" />
              <div>
                <h6 className="font-extrabold text-xl2">{t('Calculating your body mass index')}</h6>
                <p className="text-base text-dark opacity-65 mt-4">
                  {t(
                    'BMI is widely used as a risk factor for the development of or the prevalence of several health issues.',
                  )}
                </p>
              </div>
            </div>
          )}
        </>
      )}
      {props?.attributes?.name === 'current-weight' && (
        <div>
          <div className="relative mt-8">
            <div
              className={`${String(activeUnit) === 'lbs' ? 'relative opacity-100 visible delay-300 top-0' : ' top-8 delay-0 absolute opacity-0 invisible'} transition-all min-w-full duration-300 `}
            >
              <input
                className=" block w-full p-2 border border-borderInput bg-lightGray rounded-r12 text-xl6 font-extrabold outline-none text-dark placeholder:text-darkOpacity text-center"
                type="number"
                inputMode="numeric"
                onChange={(e) => setInputVal(String(e.target.value))}
                placeholder="Your weight"
                value={inputVal}
              />
            </div>
            <div
              className={`${String(activeUnit) === 'kg' ? 'relative opacity-100 visible delay-300 top-0' : ' top-8 delay-0 absolute opacity-0 invisible'} transition-all min-w-full duration-300`}
            >
              <input
                className=" block w-full p-2 border border-borderInput bg-lightGray rounded-r12 text-xl6 font-extrabold outline-none text-dark placeholder:text-darkOpacity text-center"
                type="number"
                inputMode="numeric"
                onChange={(e) => setInputVal(String(e.target.value))}
                placeholder="Your weight"
                value={inputVal}
              />
            </div>
          </div>
          <BMITooltip bmi={bmi} answers={props.answers} unit={activeUnit} />
        </div>
      )}
      {props?.attributes?.name === 'goal-weight' && (
        <div>
          <div className="relative mt-8">
            <div
              className={`${String(activeUnit) === 'lbs' ? 'relative opacity-100 visible delay-300 top-0' : ' top-8 delay-0 absolute opacity-0 invisible'} transition-all min-w-full duration-300 `}
            >
              <input
                className=" block w-full p-2 border border-borderInput bg-lightGray rounded-r12 text-xl6 font-extrabold outline-none text-dark placeholder:text-darkOpacity text-center"
                type="number"
                inputMode="numeric"
                onChange={(e) => setInputVal(String(e.target.value))}
                placeholder="Goal weight"
                value={inputVal}
              />
            </div>
            <div
              className={`${String(activeUnit) === 'kg' ? 'relative opacity-100 visible delay-300 top-0' : ' top-8 delay-0 absolute opacity-0 invisible'} transition-all min-w-full duration-300`}
            >
              <input
                className=" block w-full p-2 border border-borderInput bg-lightGray rounded-r12 text-xl6 font-extrabold outline-none text-dark placeholder:text-darkOpacity text-center"
                type="number"
                inputMode="numeric"
                onChange={(e) => setInputVal(String(e.target.value))}
                placeholder="Goal weight"
                value={inputVal}
              />
            </div>
          </div>
          {!isSmallBmi && (
            <>
              {goalWeight < 0 ? (
                <GetMoving percent={-goalWeight} lose="gain" />
              ) : (
                <GetMoving percent={goalWeight} lose="lose" />
              )}
            </>
          )}
          <BMITooltip bmi={bmi} percent={true} answers={props.answers} unit={activeUnit} />
        </div>
      )}
    </div>
  )
}
