import React, { useEffect, useState } from 'react'
import { Answer, Answers, Question } from '@/types/types'
import Content from '@/components/QuestionsUI/Content'
import RadioButton from '@/components/QuestionsUI/RadioButton'
import Input from '@/components/QuestionsUI/Input'
import { Button } from '@/components/Button/Button'
import CheckboxButton from '@/components/QuestionsUI/CheckboxButton'
import { useTranslation } from 'react-i18next'
import { RangeBody } from '../QuestionsUI/RangeBody'
import { BodyAreas } from '../QuestionsUI/BodyAreas'
import HumanSizes from '../QuestionsUI/HumanSizes'
import { Profile } from '../QuestionsUI/Profie'
import { Graph } from '../QuestionsUI/Graph'
import { LifestyleActive } from '../QuestionsUI/LifestyleActive'
import ProgressSteps from '../QuestionsUI/ProgressSteps'
import Image from '@/components/Image/Image'
import CheckboxCard from '@/components/QuestionsUI/CheckboxCard'
import { DatePicker } from '@/components/QuestionsUI/DatePicker'
import { PersonalizedGraphic } from '@/components/QuestionsUI/PersonalizedGraphic'
import { PurchaseWithName } from '@/components/QuestionsUI/PurchaseWithName'
import RadioCard from '../QuestionsUI/RadioCard'
import { TitleWithWeight } from '@/components/QuestionsUI/TitleWithWeight'
import { getValue } from '@/helpers/getObjectValue'
import { parseQuestion } from '@/lib/imageCashe'
import { Loader } from '../QuestionsUI/Loader'
import { trackEvent } from '@/helpers/facebookPixelEvents'

interface QuestionStepProps {
  hideHeader: (hideHeader: boolean) => void
  hideBtnHeader: (hideHeader: boolean) => void
  hideNav: (hideNav: boolean) => void
  hideBackHeader: (hideBackHeader: boolean) => void
  onAnswer: (answer: Answer, step?: number) => void
  onSubmit: () => void
  onShowNextButton: () => boolean
  onNextQuestionClick: () => void
  onBackClick: () => void
  onNextStepClick: () => void
  handleRemoveAnswer: (answer: Answer) => void
  isShowNextButton?: boolean
  question: Question
  nextQuestion: Question | null
  questionsLength: number
  numberQuestion: number
  step: number
  clickedBackButton: boolean
  active: boolean
  answers: Answers
  stepsLength: number
  removeDelay: boolean
  currentQuestion: number
  error: string
}
export default function QuestionStep(props: QuestionStepProps) {
  const { t } = useTranslation()
  const [hideHeader, setHideHeader] = useState(false)
  const [isAgreeRules, setIsAgreeRules] = useState(false)
  const [isShakeAlert, setIsShakeAlert] = useState(false)
  const showBtnContinue: string[] = ['goal-weight', 'current-weight', 'human-height', 'age']
  const isPageHumanHeight = props?.question?.fieldName === 'human-height'
  const isPageHumanValue = showBtnContinue?.includes(props?.question?.fieldName ?? '')

  parseQuestion(props.nextQuestion)

  useEffect(() => {
    if (props.question.noRequiredFields && props.active) {
      props.onAnswer(
        { [props.question?.name ?? 'test-' + props.step + props.currentQuestion]: [''] },
        props.numberQuestion,
      )
    }
  }, [props.active, props.question.noRequiredFields])

  useEffect(() => {
    if (props.active && props.question.hideHeader) {
      props.hideHeader(true)
      setHideHeader(true)
    } else if (!props.question.hideHeader && props.active) {
      props.hideHeader(false)
      setHideHeader(false)
    }
  }, [props.active, props.hideHeader])

  useEffect(() => {
    if (props.question.skipStep && props.active) {
      const event = getValue('important-event', props.answers)

      if (event === 'No Events Any Time Soon' && !props.clickedBackButton) {
        props.onNextQuestionClick()
      } else if (event === 'No Events Any Time Soon' && props.clickedBackButton) {
        props.onBackClick()
      }
    }
  }, [props.question.skipStep, props.active, props.clickedBackButton])

  useEffect(() => {
    if (props.active && props.question.hideBtnHeader) {
      props.hideBtnHeader(true)
    } else if (!props.question.hideBtnHeader && props.active) {
      props.hideBtnHeader(false)
    }
  }, [props.active, props.hideBtnHeader])

  useEffect(() => {
    if (props.active && props.question.hideNav) {
      props.hideNav(true)
    } else if (!props.question.hideNav && props.active) {
      props.hideNav(false)
    }
  }, [props.active, props.hideNav])

  useEffect(() => {
    if (props.active && props.question.amplitudeEvent) {
      trackEvent(props.question.amplitudeEvent)
    }
  }, [props.active, props.question.amplitudeEvent])

  useEffect(() => {
    if (props.active && props.question.hideBackHeader) {
      props.hideBackHeader(true)
    } else if (!props.question.hideBackHeader && props.active) {
      props.hideBackHeader(false)
    }
  }, [props.active, props.hideBackHeader])

  return (
    <div
      className={
        (props.question?.className ? 'pb-20' : '') +
        (props.active ? '' : ' absolute opacity-0 invisible h-1 overflow-hidden w-full') +
        (hideHeader ? '' : '') +
        (props.currentQuestion + 1 === props.questionsLength && props.step + 1 !== props.stepsLength
          ? ' '
          : '')
      }
    >
      {props.question?.name && (
        <h2
          className={`
          ${props.question.hideBackHeader ? '!text-left' : ''} 
          ${'mt-5 text-dark text-xl4 font-extrabold -mx-1 tracking-tight'} 
          ${
            props.step === 0 && props.currentQuestion === 0
              ? 'text-center mt-5 text-black text-xl4 font-semibold'
              : 'font-extrabold text-xl3 mt-3'
          } 
          ${' col-span-2  mt-3 pt-0 mb-0 relative z-10'}
          ${props.question?.nameClass ? props.question?.nameClass : ''}
          `}
          dangerouslySetInnerHTML={{ __html: props.question.name }}
        ></h2>
      )}
      {props.question?.paragraph && (
        <p
          className={
            'mt-2 text-[#727272] tracking-tight text-xl [&>strong]:text-dark font-normal  relative z-10'
          }
          dangerouslySetInnerHTML={{
            __html: props.question.paragraph,
          }}
        ></p>
      )}

      <div className={'mt-6 relative steps'}>
        {props?.question?.components.map((el, index) => (
          <div
            key={index}
            className={`${el.bodyAreas ? 'w-2/4' : ''} ${el.radioCard || el.checkboxCard ? 'inline-block w-2/4 p-2 align-bottom relative -top-2 odd:pl-0 even:pr-0' : ''} ${el.radioButton?.halfWidth ? 'inline-block w-2/4 p-2 pt-4 pb-0 align-bottom relative top-2 odd:pr-0 even:pl-0' : ''} step`}
          >
            {el?.loader && (
              <Loader
                answers={props.answers}
                onAnswer={props.onAnswer}
                active={props.active}
                triggerNextStep={props.onNextQuestionClick}
              />
            )}
            {el?.titleWithWeight && <TitleWithWeight answers={props.answers} />}
            {el?.content && (
              <Content
                answers={props.answers}
                onAnswer={props.onAnswer}
                content={el.content}
                active={props.active}
              />
            )}
            {el?.datePicker && (
              <DatePicker
                name={props.question.fieldName ?? 'date-picker'}
                onAnswer={props.onAnswer}
                answers={props.answers}
              />
            )}
            {el.personalizedGraphic && (
              <PersonalizedGraphic
                active={props.active}
                answers={props.answers}
                onAnswer={props.onAnswer}
              />
            )}
            {el.purchaseWithName && (
              <PurchaseWithName
                active={props.active}
                answers={props.answers}
                onAnswer={props.onAnswer}
              />
            )}
            {el?.progress && (
              <ProgressSteps
                active={props.active}
                onAnswer={props.onAnswer}
                src={el?.progress}
                answers={props.answers}
              />
            )}
            {el?.profile && (
              <Profile active={props.active} answers={props.answers} onAnswer={props.onAnswer} />
            )}
            {el?.graph && (
              <Graph
                active={props.active}
                answers={props.answers}
                onAnswer={props.onAnswer}
                content={el.graph}
              />
            )}
            {el?.radioCard && (
              <RadioCard
                onAnswer={props.onAnswer}
                name={`${props.question.fieldName ?? `radio-button-${props.numberQuestion}`}`}
                attributes={el.radioCard}
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                triggerNextStep={!props.question.nextButton ? props.onNextQuestionClick : () => {}}
                answers={props.answers}
                // smallWidth={!!props.question?.background}
              />
            )}
            {el?.radioButton && (
              <RadioButton
                onAnswer={props.onAnswer}
                name={`${props.question.fieldName ?? `radio-button-${props.numberQuestion}`}`}
                attributes={el.radioButton}
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                triggerNextStep={!props.question.nextButton ? props.onNextQuestionClick : () => {}}
                answers={props.answers}
                // smallWidth={!!props.question?.background}
              />
            )}
            <div>
              {el?.checkboxButton && (
                <CheckboxButton
                  step={props.numberQuestion}
                  onAnswer={props.onAnswer}
                  handleRemoveAnswer={props.handleRemoveAnswer}
                  name={`${props.question.fieldName ?? `checkbox-button-${props.numberQuestion}`}`}
                  attributes={el.checkboxButton}
                  answers={props.answers}
                />
              )}
            </div>
            <div>
              {el?.checkboxCard && (
                <CheckboxCard
                  step={props.numberQuestion}
                  onAnswer={props.onAnswer}
                  handleRemoveAnswer={props.handleRemoveAnswer}
                  name={`${props.question.fieldName ?? `checkbox-button-${props.numberQuestion}`}`}
                  attributes={el.checkboxCard}
                  answers={props.answers}
                />
              )}
            </div>
            {el?.bodyAreas && (
              <BodyAreas
                answers={props.answers}
                step={props.step}
                question={props.currentQuestion}
              />
            )}
            {el?.humanSizes && (
              <HumanSizes
                onAnswer={props.onAnswer}
                attributes={el.humanSizes}
                answers={props.answers}
                active={props.active}
                isAgreeRules={isAgreeRules}
                setIsAgreeRules={setIsAgreeRules}
                isShakeAlert={isShakeAlert}
              />
            )}
            {el?.rangeBody && (
              <RangeBody
                step={props.numberQuestion}
                onAnswer={props.onAnswer}
                handleRemoveAnswer={props.handleRemoveAnswer}
                name={`${props.question.fieldName ?? `range-body-${props.numberQuestion}`}`}
                attributes={el.rangeBody}
                isActive={props.active}
                answers={props.answers}
              />
            )}
            {el?.lifestyleActive && (
              <LifestyleActive
                onAnswer={props.onAnswer}
                name={`${props.question.fieldName ?? 'lifestyle'}`}
                attributes={el.lifestyleActive}
                isActive={props.active}
                answers={props.answers}
              />
            )}
            {el?.input && (
              <Input
                name={el.input.name}
                handleRemoveAnswer={props.handleRemoveAnswer}
                onAnswer={props.onAnswer}
                attributes={el.input}
                error={props.error}
                step={props.numberQuestion}
                disabled={props.onShowNextButton()}
                answers={props.answers}
              />
            )}
          </div>
        ))}
        {props.question?.background && (
          <div>
            <Image
              className={`fixed h-auto z-[-1] ${props.question?.background?.className || ''}`}
              width={props.question?.background?.width}
              height={props.question?.background?.height}
              src={props.question?.background.image.src}
              webp={props.question?.background.image.srcWebp}
              alt="reviw image"
            />
          </div>
        )}
      </div>

      <div
        className={
          'bottom-4 container button fixed  max-w-content w-full  left-1/2 -translate-x-1/2 z-30'
        }
      >
        {((props.numberQuestion + 1 !== props.questionsLength &&
          props.question.nextButton &&
          props.active) ||
          isPageHumanValue) && (
          <div
            className={`
              text-white
              ${props.isShowNextButton && !isPageHumanValue ? 'opacity-0' : 'opacity-100'}
            `}
          >
            <Button
              onClick={() => {
                if (isPageHumanHeight && !isAgreeRules) {
                  setIsShakeAlert(true)
                  setTimeout(() => setIsShakeAlert(false), 0)
                } else {
                  props.onNextQuestionClick()
                }
              }}
              disabled={props.isShowNextButton}
              className={`
                btn-large text-xl2
                ${isPageHumanHeight && !isAgreeRules ? 'opacity-50' : ''}
                ${isPageHumanValue ? 'disabled:opacity-50' : 'disabled:opacity-0'} 
              `}
            >
              {props.question?.nextButtonTitle ?? t('NEXT')}
            </Button>
          </div>
        )}
        {props.active &&
          props.numberQuestion + 1 === props.questionsLength &&
          props.step + 1 < props.stepsLength &&
          props.question.nextButton && (
            <div className="text-white">
              <Button
                onClick={props.onNextStepClick}
                disabled={props.isShowNextButton}
                className="btn-large text-xl2"
              >
                {props.question?.nextButtonTitle ?? t('Continue')}
              </Button>
            </div>
          )}
        {props.numberQuestion + 1 === props.questionsLength &&
          props.step + 1 === props.stepsLength &&
          props.active && (
            <div className="text-white">
              <Button
                disabled={props.isShowNextButton}
                onClick={props.onSubmit}
                className="btn-large text-xl2"
              >
                {props.question?.nextButtonTitle ?? t('NEXT')}
              </Button>
            </div>
          )}
        {props.question.skipStep && (
          <div className="px-5 bg-transparent pb-5 mt-4">
            <button
              onClick={() => {
                props.onAnswer({
                  'important-event': ['No Events Any Time Soon'],
                })
                props.onNextQuestionClick()
              }}
              className="btn-large uppercase p-0 w-full bg-transparent text-dark text-xl2 font-extrabold text-center"
            >
              {t('Skip This Step')}
            </button>
          </div>
        )}
      </div>
    </div>
  )
}
